import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import React from "react";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DataTable } from "primereact/datatable";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";
import { observable } from "mobx";
import { Link } from "react-router-dom";
import { UserRoleHelper } from "../../utils/UserRoleHelper";


@observer
export class AwbDetailModalComponent extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable data: any = {};
  dataSource = new DataSource<any>();

  @observable userRole = "";
  @observable showSensitiveData: any = {};
  @observable allowSensitiveData = false;

  constructor(props, context) {
    super(props, context);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      show: false,
      ipAddress: '',
      base64Img:'',
    };

    this.userRole = UserRoleHelper.getCurrentUserRole();
    this.allowSensitiveData = UserRoleHelper.hasRole(this.userRole, [
      "Developer Mode",
      "Developer",
      "Control Tower",
    ]);
  }
  componentDidMount() { 
    this.monitoringRestService
      .get('https://geolocation-db.com/json/')
      .subscribe(
        response => {
          this.setState({
            ipAddress:response['IPv4']
          })
        },
        (err) => {
          this.dataSource.loading = false;
         },
        () => { },
      );
  }

  handleShow(awbNumber) {
    this.loadData(awbNumber);
    this.setState({ show: true });
  }

  handleClose() {
    this.setState({ show: false });
  }

  insertLog (key){
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    this.monitoringRestService
      .post("logger/insert", {
        awb_number: this.data!.receiptNumber,
        ip: this.state.ipAddress?this.state.ipAddress:'',
        key: key,
        type: "",
        user_id: String(userData.user_id),
        value: this.data![key]
    })
      .subscribe();
  }

  handleShowSensitiveData(key) {
    this.insertLog(key);
    this.showSensitiveData[key] = true;
  }

  loadData(awbNumber) {
    this.showSensitiveData = {};
    this.clearData();
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("awb-basic", { awb_number: awbNumber })
      .subscribe(
        (response) => {
          const awb = response[0];
          if (awb) {
            const mappedTrackings = awb.tracking_awb.map((d) => {
              return {
                ...d,
                trackingDateTime: d.history_date_time,
                trackingType: d.awb_status_name,
                trackingSite: d.branch_name,
                city: d.city_name,
                noteInternal: d.note_internal,
                notePublic: d.note_public,
              }
            });
            const mappedData = {
              ...awb,
              asal: awb.from_district_code,
              asalKecamatan: awb.from_district_name,
              asalKota: awb.from_city_name,
              asalProvince: awb.from_province_name,
              partnerName: awb.partner_name,
              pengirim: awb.customer_account_name,
              reseller: awb.shipper_name,
              nohpReseller: awb.shipper_phone,
              tujuan: awb.to_district_code,
              tujuanKecamatan: awb.to_district_name,
              tujuanKota: awb.to_city_name,
              tujuanProvince: awb.to_province_name,
              penerima: awb.consignee_name,
              nohpPenerima: awb.consignee_phone,
              perwakilan: awb.representative_code,
              tujuanZone: awb.to_zone_name,
              receiptNumber: awb.awb_number,
              prtRecipientAddressType: awb.consignee_address_type,
              keterangan: awb.notes,
              prtParcelContent: awb.parcel_content,
              layanan: awb.package_type_code,
              etd: awb.etd,
              etdMin: awb.lead_time_min_days,
              etdMax: awb.lead_time_max_days,
              etdPartner: awb.est_etd,
              paymentMethod: awb.payment_type,
              koli: awb.stt_koli,
              berat: awb.total_weight,
              beratAsli: awb.total_weight_real,
              beratVolume: awb.total_weight_volume,
              codValue: awb.total_cod_value,
              prtParcelValue: awb.parcel_value,
              packingBiaya: awb.packing_price,
              asuransiNilai: awb.insurance_value,
              asuransiBiaya: awb.insurance,
              harga: awb.base_price,
              asuransiAdm: awb.insurance_adm,
              biaya: awb.stt_totalbiaya,
              totalbiaya: awb.stt_totalbiaya,
              priceAfterDisc: awb.subtotal_price,
              prtReferenceNo: awb.pr_reference_no,
              bagNumber: awb.bag_number,
              noKonfirmasi: awb.stt_nokonfirmasi,
              asalGeraiKode: awb.branch_id,
              asalGerai: awb.branch_name,
              username: awb.ref_user_id,
              tglTransaksi: awb.awb_date,
              tglFoto: awb.manifest_date_time,
              slaMaxDateTimeInternal: awb.delivery_sla_date,
              overSlaInternal: awb.over_sla_days,
              slaMaxDateTimeExternal: awb.delivery_sla_date,
              overSlaExternal: awb.over_sla_days,
              slaMaxDateTimeInternalPartner: awb.estimate_delivery_sla_date,
              overSlaInternalPartner: awb.est_over_sla_days,
              trackingDateTime: awb.last_valid_status_date_time,
              status: awb.status,
              isCutOff: awb.cut_off,
              trackingNotes: mappedTrackings,
              referenceNoGroup: awb.reference_no_group,
            }
            this.data = mappedData;
            this.populateDataSource();
          }
        },
        (error) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  clearData() {
    this.data = {};
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  populateDataSource() {
    if (this.data!.trackingNotes && this.data.trackingNotes.length) {
      console.log(this.data);
      this.dataSource.setData(this.data.trackingNotes);
      this.dataSource.setTotal(this.data.trackingNotes.length);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  formatDateTime(date) {
    return date ? moment(date).utc().format("DD-MM-YYYY HH:mm") : "";
  }

  formatDate(date) {
    return date ? moment(date).utc().format("DD-MM-YYYY") : "";
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    const colValue = rowData[column.field!];
    const formattedValue = colValue ? this.formatDateTime(colValue) : null;
    return formattedValue;
  };

  render() {
    return (
      <div>
        <Dialog
          header="Awb Detail"
          appendTo={document.body}
          visible={this.state.show}
          style={{
            width: "80vw",
            height: "100vh",
            overflow: "auto",
            zIndex: "10000",
          }}
          modal={true}
          onHide={() => this.setState({ show: false })}
          maximizable
        >
          <div className="row m-1">
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">
                  <span className="font-bold">Asal</span>
                </div>

                <div className="col-md-8">
                  {this.data!.asal}
                  <p>
                    {this.data!.asalKecamatan}, {this.data!.asalKota} -{" "}
                    {this.data!.asalProvince}
                  </p>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Partner</span>
                </div>

                <div className="col-md-8">{this.data!.partnerName}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Pengirim</span>
                </div>

                <div className="col-md-8">{this.data!.pengirim}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Reseller</span>
                </div>

                <div className="col-md-8">{this.data!.reseller}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Telp Reseller</span>
                </div>

                <div className="col-md-8">{this.data!.nohpReseller}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tujuan</span>
                </div>

                <div className="col-md-8">
                  {this.data!.tujuan}
                  <p>
                    {this.data!.tujuanKecamatan}, {this.data!.tujuanKota} -{" "}
                    {this.data!.tujuanProvince}
                  </p>
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Penerima</span>
                </div>

                <div className="col-md-8">{this.data!.penerima}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Telp Penerima</span>
                </div>

                <div className="col-md-8">
                  {this.showSensitiveData.nohpPenerima ? this.data!.nohpPenerima : (
                    <a
                      className="cursor-pointer"
                      onClick={() => this.handleShowSensitiveData('nohpPenerima')}
                    >
                      Lihat Detail
                    </a>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Perwakilan</span>
                </div>

                <div className="col-md-8">{this.data!.perwakilan}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Zona</span>
                </div>

                <div className="col-md-8">{this.data!.tujuanZone}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Gambar</span>
                </div>

                <div className="col-md-8">
                  {this.data!.receiptNumber ? (
                    <a
                      target="_blank"
                      href={
                        "/monitor/trackingNote/showImage/" +
                        this.data.receiptNumber
                      }
                    >
                      Lihat Gambar
                    </a>
                  ) : (
                    "-"
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tipe Alamat Penerima</span>
                </div>

                <div className="col-md-8">
                  {this.showSensitiveData ? this.data!.prtRecipientAddressType : "-"}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Keterangan</span>
                </div>

                <div className="col-md-8">
                  {this.showSensitiveData.keterangan ? this.data!.keterangan : (
                    <a
                      className="cursor-pointer"
                      onClick={() => this.handleShowSensitiveData('keterangan')}
                    >
                      Lihat Detail
                    </a>
                  )}
                </div>
              </div>

              {this.allowSensitiveData && (
                <div className="row mt-3">
                  <div className="col-md-4">
                    <span className="font-bold">Isi Barang</span>
                  </div>

                  <div className="col-md-8">
                    {this.showSensitiveData.prtParcelContent ? this.data!.prtParcelContent : (
                      <a
                        className="cursor-pointer"
                        onClick={() => this.handleShowSensitiveData('prtParcelContent')}
                      >
                        Lihat Detail
                      </a>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">
                  <span className="font-bold">Layanan</span>
                </div>

                <div className="col-md-8">
                  {this.data!.layanan} ({this.data!.etd})
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Est SLA Partner</span>
                </div>

                <div className="col-md-8">
                  {this.data!.layanan} ({this.data!.etdPartner})
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tipe Bayar</span>
                </div>

                <div className="col-md-8">{this.data!.paymentMethod}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Koli</span>
                </div>

                <div className="col-md-8">{this.data!.koli}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Berat</span>
                </div>

                <div className="col-md-8">{this.data!.berat}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Berat Asli</span>
                </div>

                <div className="col-md-8">{this.data!.beratAsli}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Berat Volume</span>
                </div>

                <div className="col-md-8">{this.data!.beratVolume}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Berat Volume (Kg)</span>
                </div>

                <div className="col-md-8">{this.data!.beratVolume}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Nilai COD</span>
                </div>

                <div className="col-md-8">{this.data!.codValue}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Parcel Value</span>
                </div>

                <div className="col-md-8">
                  {this.showSensitiveData.prtParcelValue ? this.data!.prtParcelValue : (
                    <a
                      className="cursor-pointer"
                      onClick={() => this.handleShowSensitiveData('prtParcelValue')}
                    >
                      Lihat Detail
                    </a>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Biaya Packing</span>
                </div>

                <div className="col-md-8">{this.data!.packingBiaya}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Nilai Barang (Asuransi)</span>
                </div>

                <div className="col-md-8">
                  {this.showSensitiveData.asuransiNilai ? this.data!.asuransiNilai : (
                    <a
                      className="cursor-pointer"
                      onClick={() => this.handleShowSensitiveData('asuransiNilai')}
                    >
                      Lihat Detail
                    </a>
                  )}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Biaya Asuransi</span>
                </div>

                <div className="col-md-8">{this.data!.asuransiBiaya}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Harga</span>
                </div>

                <div className="col-md-8">{this.data!.harga}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Packing & Asuransi</span>
                </div>

                <div className="col-md-8">
                  {this.data
                    ? this.data.packingBiaya +
                      this.data.asuransiBiaya +
                      this.data.asuransiAdm
                    : 0}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Total Ongkir</span>
                </div>

                <div className="col-md-8">{this.data!.biaya}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Grand Total</span>
                </div>

                <div className="col-md-8">{this.data!.totalbiaya}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Price After Discount</span>
                </div>

                <div className="col-md-8">{this.data!.priceAfterDisc}</div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">
                  <span className="font-bold">No Resi</span>
                </div>

                <div className="col-md-8">{this.data!.receiptNumber}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">No Order Partner</span>
                </div>

                <div className="col-md-8">{this.data!.prtReferenceNo}</div>
              </div>

              {this.data!.referenceNoGroup && (
                <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Resi Master</span>
                </div>

                <div className="col-md-8">{this.data!.referenceNoGroup}</div>
              </div>
              )}

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">No Gab. Paket</span>
                </div>

                <div className="col-md-8">{this.data!.bagNumber}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">No Konfirmasi</span>
                </div>

                <div className="col-md-8">{this.data!.noKonfirmasi}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Gerai</span>
                </div>

                <div className="col-md-8">
                  {this.data!.asalGeraiKode} - {this.data!.asalGerai}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">User</span>
                </div>

                <div className="col-md-8">{this.data!.username}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tanggal Transaksi</span>
                </div>

                <div className="col-md-8">
                  {this.formatDate(this.data!.tglTransaksi)}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tanggal Foto</span>
                </div>

                <div className="col-md-8">
                  {this.formatDateTime(this.data!.tglFoto)}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Over SLA (Internal)</span>
                </div>

                <div className="col-md-8">
                  {this.formatDate(this.data!.slaMaxDateTimeInternal)} (
                  {this.data!.overSlaInternal})
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Over SLA (External)</span>
                </div>

                <div className="col-md-8">
                  {this.formatDate(this.data!.slaMaxDateTimeExternal)} (
                  {this.data!.overSlaExternal})
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Est Over SLA Partner</span>
                </div>

                <div className="col-md-8">
                  {this.data!.slaMaxDateTimeInternalPartner ? (
                    `${this.formatDate(this.data!.slaMaxDateTimeInternalPartner)} (${this.data!.overSlaInternalPartner})`
                  ) : "-"}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Tanggal Status</span>
                </div>

                <div className="col-md-8">
                  {this.formatDateTime(this.data!.trackingDateTime)}
                </div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Status</span>
                </div>

                <div className="col-md-8">{this.data!.status}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Cut Off</span>
                </div>

                <div className="col-md-8">{this.data!.isCutOff}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Threshold</span>
                </div>

                <div className="col-md-8">{this.data!.edd_treshold}</div>
              </div>

              <div className="row mt-3">
                <div className="col-md-4">
                  <span className="font-bold">Max X Days (EDD)</span>
                </div>

                <div className="col-md-8">
                  {this.formatDate(this.data!.edd_delivery_sla_date)}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mx-5 mt-10">
              <em>
                * Tampilkan informasi sensitif seperti no telp penerima, alamat penerima/keterangan, nilai barang,
                dan jenis barang dengan cara klik lihat detail. Mohon diperhatikan, aksi ini akan di log di
                sistem kami untuk alasan keamanan
              </em>
            </div>
          </div>
          <div className="row m-5">
            <DataTable
              emptyMessage={"Tidak ada data untuk ditampilkan."}
              scrollable={true}
              // scrollHeight="500px"
              style={{ width: "100%" }}
              className="mt-4"
              value={this.dataSource.data}
              loading={this.dataSource.loading}
            >
              <Column
                field="trackingDateTime"
                header="Tgl"
                sortable={false}
                style={{ width: "120px" }}
                body={this.columnDateFormat}
              />
              <Column
                field="trackingType"
                header="Status"
                sortable={false}
                style={{ width: "60px" }}
              />
              <Column
                field="trackingSite"
                header="Gerai"
                sortable={false}
                style={{ width: "140px" }}
                body={(rowData, column: ColumnProps) => {
                  return (
                    <span>
                      {rowData[column.field!]} ({rowData["city"]})
                    </span>
                  );
                }}
              />
              <Column
                field="noteInternal"
                header="Catatan (Internal)"
                sortable={false}
                style={{ width: "200px" }}
              />
              <Column
                field="notePublic"
                header="Catatan (Public)"
                sortable={false}
                style={{ width: "200px" }}
              />
            </DataTable>
          </div>
        </Dialog>
      </div>
    );
  }
}
