import "./LayoutAdmin.scss";

import classNames from "classnames";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import React from "react";

import {LayoutAdminInlineProfile} from "./LayoutAdminInlineProfile";
import {LayoutAdminMenu} from "./LayoutAdminMenu";
import {LayoutAdminTopbar} from "./LayoutAdminTopbar";
import {Redirect} from "react-router-dom";
import {autorun} from "mobx";

export class LayoutAdmin extends React.Component<any, any> {
  menuClick: any;
  menu: any;
  sidebar: HTMLDivElement | null;

  constructor(props) {
    super(props);

    this.state = {
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };

    this.onWrapperClick = this.onWrapperClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.onSidebarClick = this.onSidebarClick.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.createMenu();
  }

  onWrapperClick(event) {
    if (!this.menuClick) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }

    this.menuClick = false;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.isDesktop()) {
      if (this.state.layoutMode === "overlay") {
        this.setState({
          overlayMenuActive: !this.state.overlayMenuActive,
        });
      } else if (this.state.layoutMode === "static") {
        this.setState({
          staticMenuInactive: !this.state.staticMenuInactive,
        });
      }
    } else {
      const mobileMenuActive = this.state.mobileMenuActive;
      this.setState({
        mobileMenuActive: !mobileMenuActive,
      });
    }

    event.preventDefault();
  }

  onSidebarClick(event) {
    this.menuClick = true;
  }

  onMenuItemClick(event) {
    if (!event.item.items) {
      this.setState({
        overlayMenuActive: false,
        mobileMenuActive: false,
      });
    }
  }

  createMenu() {
    this.menu = [
      {
        name: "dashboard",
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        command: () => {
          window.location = "/admin/dashboard" as any;
        },
        href: "/admin/dashboard",
        externalLink: false,
        newtab: false,
        auth: false,
      },
      // {
      //   name: "monitoring_pod",
      //   label: "Monitoring POD",
      //   icon: "pi pi-fw pi-list",
      //   command: () => {
      //     window.location = "/admin/monitoring/pod-new" as any;
      //   },
      //   href: "/admin/monitoring/pod-new",
      //   externalLink: false,
      //   newtab: false,
      //   auth: true,
      // },
      {
        name: "monitoring_pod",
        label: "Monitoring POD (New)",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/monitoring/pod-sf" as any;
        },
        href: "/admin/monitoring/pod-sf",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "check_history_resi",
        label: "Check History Resi",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/check-history-resi" as any;
        },
        href: "/admin/check-history-resi",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "monitoring_urgent",
        label: "Monitoring Urgent",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/monitoring-urgent" as any;
        },
        href: "/admin/monitoring-urgent",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "audit_tracking_cod",
        label: "Audit Tracking COD",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/auditor-tracking-cod" as any;
        },
        href: "/admin/auditor-tracking-cod",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "masa_ro_broken",
        label: "Masa RO & Broken",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          {
            name: "summary_masa_ro_last_mile",
            label: "Summary Masa RO Last Mile",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/summary-masa-ro-last-mile" as any;
            },
            href: "/admin/summary-masa-ro-last-mile",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "breakdown_masa_ro_last_mile",
            label: "Breakdown Masa RO Last Mile",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/breakdown-masa-ro-last-mile" as any;
            },
            href: "/admin/breakdown-masa-ro-last-mile",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "summary_paket_broken",
            label: "Summary Paket Broken",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring-masa-ro-broken" as any;
            },
            href: "/admin/monitoring-masa-ro-broken",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "breakdown_paket_broken",
            label: "Breakdown Paket Broken",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring-paket-broken" as any;
            },
            href: "/admin/monitoring-paket-broken",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ]
      },
      {
        name: "monitoring_kepala_kurir",
        label: "Monitoring Kepala Kurir",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          // {
          //   name: "monpod_kepala_kurir",
          //   label: "Monitoring POD Kepala Kurir",
          //   icon: "pi pi-fw pi-list",
          //   command: () => {
          //     window.location = "/admin/monitoring-pod-new-palkur" as any;
          //   },
          //   href: "/admin/monitoring-pod-new-palkur",
          //   externalLink: false,
          //   newtab: false,
          //   auth: true,
          // },
          {
            name: "monpod_kepala_kurir",
            label: "Monitoring POD Kepala Kurir (New)",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring-pod-sf-palkur" as any;
            },
            href: "/admin/monitoring-pod-sf-palkur",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "metabase_over_sla",
            label: "[Metabase] Over SLA",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring-pod-palkur/over-sla" as any;
            },
            href: "/admin/monitoring-pod-palkur/over-sla",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ]
      },
      {
        name: "lpb",
        label: "Daftar Resi (LPB)",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/monitoring/awb-list-kibana" as any;
        },
        href: "/admin/monitoring/awb-list-kibana",
        externalLink: false,
        newtab: false,
        auth: true,
      },

      {
        name: "pengantaran",
        label: "Pengantaran",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          // {
          //   name: "pengantaran_process",
          //   label: "Dalam Pengantaran",
          //   icon: "pi pi-fw pi-list",
          //   command: () => {
          //     window.location = "/admin/monitoring/ant-not-dlv-redshift" as any;
          //   },
          //   href: "/admin/monitoring/ant-not-dlv-redshift",
          //   externalLink: false,
          //   newtab: false,
          //   roles: [...DataRoles, "CS CKL"],
          // },
          {
            name: "pengantaran_process",
            label: "Dalam Pengantaran (New)",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/ant-not-dlv" as any;
            },
            href: "/admin/monitoring/ant-not-dlv",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "pengantaran_not_dlv",
            label: "Resi Belum Terkirim",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/undelivered-kibana" as any;
            },
            href: "/admin/monitoring/undelivered-kibana",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "pengantaran_volume_representative",
            label: "Volume per Perwakilan Tujuan",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/manifest-perwakilan" as any;
            },
            href: "/admin/monitoring/manifest-perwakilan",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "pengantaran_volume_district",
            label: "Volume per Kecamatan Tujuan",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/manifest-perwakilan-dest-district" as any;
            },
            href: "/admin/monitoring/manifest-perwakilan-dest-district",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ],
      },
      {
        name: "retur",
        label: "Retur",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          {
            name: "retur_in_process",
            label: "Resi Retur Dalam Proses New",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location =
                "/admin/monitoring/return-in-progress-kibana" as any;
            },
            href: "/admin/monitoring/return-in-progress-kibana",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ],
      },
      {
        name: "problems",
        label: "Problems",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          {
            name: "problem_over_sla",
            label: "Over SLA",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/oversla-kibana" as any;
            },
            href: "/admin/monitoring/oversla-kibana",
            externalLink: false,
            newtab: false,
            auth: true,
          },
          {
            name: "problem_resi_xxx",
            label: "Resi XXX",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/monitoring/resi-xxx-kibana" as any;
            },
            href: "/admin/monitoring/resi-xxx-kibana",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ],
      },
      {
        name: "analytics",
        label: "Analytics",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/monitoring/analytics" as any;
        },
        href: "/admin/monitoring/analytics",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "masterdata",
        label: "Master Data",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          {
            name: "masterdata_gerai",
            label: "Master Gerai",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/masterdata/trackingsites" as any;
            },
            href: "/admin/masterdata/trackingsites",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ],
      },
      {
        name: "procedures",
        label: "Prosedur dan Ketentuan",
        icon: "pi pi-fw pi-file",
        command: () => {
          window.location =
            "hhttps://sicepatid-my.sharepoint.com/:f:/g/personal/murwanto_sicepat_com/Eh1XWKpEw61ClSGppRFlTE4BA-MDTOsFiph3RYvJ_zi1cw?e=W16EgX" as any;
        },
        href: "https://sicepatid-my.sharepoint.com/:f:/g/personal/murwanto_sicepat_com/Eh1XWKpEw61ClSGppRFlTE4BA-MDTOsFiph3RYvJ_zi1cw?e=W16EgX",
        externalLink: true,
        newtab: true,
        auth: true,
      },
      {
        name: "attendance_driver",
        label: "Attendance Driver",
        icon: "pi pi-fw pi-calendar",
        command: () => {
          window.location = "https://pod.sicepat.com/" as any;
        },
        href: "https://pod.sicepat.com/",
        externalLink: true,
        newtab: true,
        auth: true,
      },
      {
        name: "summary_monitoring_sds",
        label: "Summary Monitoring SDS",
        icon: "",
        command: () => {
          window.location = "https://sds.sicepat.com/" as any;
        },
        href: "https://sds.sicepat.com/",
        externalLink: true,
        newtab: true,
        auth: true,
      },
      {
        name: "monitoring_line_haul",
        label: "[Metabase] Monitoring Line Haul",
        icon: "pi pi-fw pi-list",
        command: () => {
          window.location = "/admin/monitoring-line-haul-metabase" as any;
        },
        href: "/admin/monitoring-line-haul-metabase",
        externalLink: false,
        newtab: false,
        auth: true,
      },
      {
        name: "settings",
        label: "Pengaturan",
        icon: "pi pi-fw pi-list",
        newtab: false,
        auth: true,
        items: [
          {
            name: "settings_role_access",
            label: "Role Akses",
            icon: "pi pi-fw pi-list",
            command: () => {
              window.location = "/admin/settings/user-permission" as any;
            },
            href: "/admin/settings/user-permission",
            externalLink: false,
            newtab: false,
            auth: true,
          },
        ],
      },
    ];
  }

  addClass(element, className) {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  }

  removeClass(element, className) {
    if (element.classList) element.classList.remove(className);
    else {
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi",
        ),
        " ",
      );
    }
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  componentDidUpdate() {
    if (this.state.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  }

  render() {
    if (!localStorage.getItem("userData")) {
      return <Redirect to={"/"} />;
    }

    // const logo =
    //   this.state.layoutColorMode === 'dark'
    //     ? 'assets/layout/images/logo-white.svg'
    //     : 'assets/layout/images/logo.svg';

    const logo = "/assets/logo-sicepat.png";

    const wrapperClass = classNames("layout-wrapper", {
      "layout-overlay": this.state.layoutMode === "overlay",
      "layout-static": this.state.layoutMode === "static",
      "layout-static-sidebar-inactive":
        this.state.staticMenuInactive && this.state.layoutMode === "static",
      "layout-overlay-sidebar-active":
        this.state.overlayMenuActive && this.state.layoutMode === "overlay",
      "layout-mobile-sidebar-active": this.state.mobileMenuActive,
    });
    const sidebarClassName = classNames("layout-sidebar", {
      "layout-sidebar-dark": this.state.layoutColorMode === "dark",
    });

    return (
      <div className={wrapperClass} onClick={this.onWrapperClick}>
        <LayoutAdminTopbar onToggleMenu={this.onToggleMenu} />

        <div
          ref={(el) => (this.sidebar = el)}
          className={sidebarClassName}
          onClick={this.onSidebarClick}
        >
          <ScrollPanel style={{height: "100%"}}>
            <div className='layout-sidebar-scroll-content'>
              <div className='layout-logo'>
                <img
                  alt='Logo'
                  src={logo}
                  style={{width: "172px", margin: "auto"}}
                />
              </div>
              <LayoutAdminInlineProfile />
              <LayoutAdminMenu
                model={this.menu}
                onMenuItemClick={this.onMenuItemClick}
              />
            </div>
          </ScrollPanel>
        </div>

        <div className="layout-main">
          {this.props.tag && (
            <div className="layout-tag absolute -mt-5 -ml-5 z-10">{this.props.tag}</div>
          )}
          {this.props.children}
        </div>

        <div className='layout-mask' />
      </div>
    );
  }
}
